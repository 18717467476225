var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
function linear(x, w, b) {
    var out = b;
    for (var i = 0; i < x.length; i++) {
        out += w[i] * x[i];
    }
    return out;
}
function linearLayer(x, w, b) {
    var out = __spreadArray([], b, true);
    for (var i = 0; i < out.length; i++) {
        out[i] = linear(x, w[i], b[i]);
    }
    return out;
}
function innerLayer(x, w, b) {
    return linearLayer(x, w, b).map(function (y) { return Math.max(y, 0); });
}
function inference(x, w, b) {
    for (var i = 0; i < w.length - 1; i++) {
        x = innerLayer(x, w[i], b[i]);
    }
    x = linearLayer(x, w[w.length - 1], b[b.length - 1]);
    return x;
}
function argmax(x) {
    if (x.length === 0) {
        return -1;
    }
    var out = 0;
    var currMax = x[0];
    for (var i = 1; i < x.length; i++) {
        if (x[i] > currMax) {
            out = i;
            currMax = x[i];
        }
    }
    return out;
}
function getEdgeValues(x, w) {
    var out = new Array(w.length);
    for (var i = 0; i < w.length; i++) {
        out[i] = new Array(w[i].length);
        for (var j = 0; j < w[i].length; j++) {
            out[i][j] = w[i][j] * x[j];
        }
    }
    return out;
}
// build the executed network
function inferenceRecord(net, x) {
    var out = __assign(__assign({}, net), { stages: new Array(net.nLayers + 1), edgeValues: new Array(net.nLayers) });
    for (var i = 0; i < net.nLayers - 1; i++) {
        out.stages[i] = __spreadArray([], x, true);
        out.edgeValues[i] = getEdgeValues(x, net.weights[i]);
        x = innerLayer(x, net.weights[i], net.biases[i]);
    }
    {
        var i = net.nLayers - 1;
        out.stages[i] = __spreadArray([], x, true);
        out.edgeValues[i] = getEdgeValues(x, net.weights[i]);
        x = linearLayer(x, net.weights[i], net.biases[i]);
    }
    out.stages[net.nLayers] = __spreadArray([], x, true);
    return out;
}
function table2Vector(table) {
    var _a;
    return (_a = Array.prototype).concat.apply(_a, table);
}
function runInference(net, table) {
    return inferenceRecord(net, table2Vector(table));
}
//console.log(linear([4, 3], [2, 1], 5));
//console.log(table2Vector([[4,3,6], [7,3,5]]))
export { inferenceRecord, argmax };
