var d3 = require('d3');
//import * as d3 from 'd3';
import { pairWise, range, flatten } from './util'
//import * from './util'

function FCNN() {

    let randomWeight = () => Math.random() * 2 - 1;


    /////////////////////////////////////////////////////////////////////////////
                          ///////    Variables    ///////
    /////////////////////////////////////////////////////////////////////////////

    function getDivDim(div, attr) {
        var width = d3.select(div)
        // get the width of div element
            .style(attr)
        // take of 'px'
            .slice(0, -2)
        // return as an integer
        return Math.round(Number(width))
    }
    var w = getDivDim(".network", "width");
    var h = getDivDim(".network", "height");

    var svg = d3.select("#graph-container").append("svg").attr("xmlns", "http://www.w3.org/2000/svg");
    var g = svg.append("g");

    var edgeWidthProportional = false;
    var edgeWidth = 0.5;
    var weightedEdgeWidth = d3.scaleLinear().domain([0, 1]).range([0, edgeWidth]);

    var edgeOpacityProportional = false;
    var edgeOpacity = 1.0
    var weightedEdgeOpacity = d3.scaleLinear().domain([0, 1]).range([0, 1]);

    var edgeColorProportional = false;
    var defaultEdgeColor = "#505050";
    var negativeEdgeColor = "#6666ff";
    var positiveEdgeColor = "#ff0000";
    var weightedEdgeColor = d3.scaleLinear().domain([-1, 0, 1]).range([negativeEdgeColor, "white", positiveEdgeColor]);

    var nodeColorProportional = true;
    var nodeDiameter = 20;
    var defaultNodeColor = "#ffffff";
    var nodeColor = d3.scaleLinear().domain([-5, 0, 5]).range([negativeEdgeColor, "white", positiveEdgeColor])
    var nodeBorderColor = "#333333";

    var betweenLayers = 160;

    const nAboveBelow = 8;
    const nodesInLayerBeforeSplitting = 20;
    const ellipsisGap = 3;
    const elipsisColor = "#000000"

    var architecture = [16, 16, 10];
    var betweenNodesInLayer = [20, 20, 20];
    var graph = {};
    var layer_offsets = [];
    var largest_layer_width = 0;
    var nnDirection = 'right';
    var showBias = false;
    var showLabels = true;
    var showArrowheads = false;
    var arrowheadStyle = "empty";

    let sup_map = {'0': '⁰', '1': '¹', '2': '²', '3': '³', '4': '⁴', '5': '⁵', '6': '⁶', '7': '⁷', '8': '⁸', '9': '⁹'};
    let sup = (s) => Array.prototype.map.call(s, (d) => (d in sup_map && sup_map[d]) || d).join('');

    let textFn = (layer_index, layer_width) => ((layer_index === 0 ? "Input" : (layer_index === architecture.length-1 ? "Output" : "Hidden")) + " Layer ∈ ℝ" + sup(layer_width.toString()));
    //let textFn = (layer_index, layer_width) => ((layer_index === architecture.length-1 ? "Output" : "Hidden") + " Layer ∈ ℝ" + sup(layer_width.toString()));
    textFn = (layer_index, layer_width) => {
        if (layer_index === 0) {
            return "Input Layer ∈ {0,1}" + sup(layer_width.toString());
        } else if (layer_index === architecture.length-1) {
            return "Output Layer ∈ ℝ" + sup(layer_width.toString());
        } else {
            return "Hidden Layer ∈ ℝ" + sup(layer_width.toString());
        }
    };
    var nominal_text_size = 12;
    var textWidth = 70;

    var marker = svg.append("svg:defs").append("svg:marker")
        .attr("id", "arrow")
        .attr("viewBox", "0 -5 10 10")
        .attr("markerWidth", 7)
        .attr("markerHeight", 7)
        .attr("orient", "auto");

    var arrowhead = marker.append("svg:path")
        .attr("d", "M0,-5L10,0L0,5")
        .style("stroke", defaultEdgeColor);

    var link = g.selectAll(".link");
    var node = g.selectAll(".node");
    var text = g.selectAll(".text");
    var elip = g.selectAll(".elip");

    /////////////////////////////////////////////////////////////////////////////
                          ///////    Methods    ///////
    /////////////////////////////////////////////////////////////////////////////

    function redraw({architecture_=architecture,
                     showBias_=showBias,
                     showLabels_=showLabels,
                     annexec_={}}={}) {

        architecture = architecture_;
        const splitList = architecture.map(layer_width => layer_width > 2*nAboveBelow);
        showBias = showBias_;
        showLabels = showLabels_;

        graph.nodes = architecture.map((layer_width, layer_index) => 
            range(layer_width).filter((node_index) => {
                return node_index < nAboveBelow || node_index >= architecture[layer_index] - nAboveBelow;
            }).map(node_index => {
                return {
                    'id':layer_index+'_'+node_index,
                    'layer':layer_index,
                    'node_index':node_index,
                    'relative_node_index': (() => {
                        if (node_index < nAboveBelow || !splitList[layer_index]) {
                            return node_index;
                        } else if (node_index >= architecture[layer_index] - nAboveBelow) {
                            return nAboveBelow + ellipsisGap + node_index - architecture[layer_index] + nAboveBelow;
                        } else {
                            console.log("this shouldn't happen");
                            return null;
                        };
                    })(),
                    'node_value': (() => {
                        return annexec_.stages[layer_index][node_index]
                    })()
                }
            })
        );

        graph.elip = splitList
            .map((layer_in, layer_index) => ({
                'id': 'e_' + layer_index,
                'inclusion': layer_in,
                'layer': layer_index
            }))
            .filter(d => d.inclusion)
            .flatMap(d => [
                {
                    'id': d.id + '_' + 0,
                    'layer': d.layer,
                    'part': 0
                },
                {
                    'id': d.id + '_' + 1,
                    'layer': d.layer,
                    'part': 1
                },
                {
                    'id': d.id + '_' + 2,
                    'layer': d.layer,
                    'part': 2
                },
            ]);

        graph.links = pairWise(graph.nodes).map((nodes) => nodes[0].map(left => nodes[1].map(right => {
            return right.node_index >= 0 ? {
                'id': left.id+'-'+right.id,
                'source': left.id,
                'target': right.id,
                'start': left,
                'end': right,
                'weight': 0.5
            } : null 
        })));
        graph.nodes = flatten(graph.nodes);
        graph.links = flatten(graph.links).filter(l => (l && (showBias ? (parseInt(l['target'].split('_')[0]) !== architecture.length-1 ? (l['target'].split('_')[1] !== '0') : true) : true)));

        let label = architecture.map((layer_width, layer_index) => { return {'id':'layer_'+layer_index+'_label','layer':layer_index,'text':textFn(layer_index, layer_width)}});

        link = link.data(graph.links, d => d.id);
        link.exit().remove();
        link = link.enter()
            .insert("path", ".node")
            .attr("class", "link")
            .merge(link);

        node = node.data(graph.nodes, d => d.id);
        node.exit().remove();
        node = node.enter()
            .append("circle")
            .attr("r", nodeDiameter/2)
            .attr("class", "node")
            .attr("id", function(d) { return d.id; })
            .on("mousedown", set_focus)
            .on("mouseup", remove_focus)
            .merge(node);

        text = text.data(label, d => d.id);
        text.exit().remove();
        text = text.enter()
            .append("text")
            .attr("class", "text")
            .attr("dy", ".35em")
            .style("font-size", nominal_text_size+"px")
            .merge(text)
            .text(function(d) { return (showLabels ? d.text : ""); });

        elip.exit().remove();
        elip = elip.data(graph.elip, d => d.id);
        elip = elip
            .enter()
            .append("circle")
            .attr("r", nodeDiameter/6)
            .attr("class", "elip")
            .attr("id", function(d) { return d.id; })
            .merge(elip);
        style();
    }

    function redistribute({betweenNodesInLayer_=betweenNodesInLayer,
                           betweenLayers_=betweenLayers,
                           nnDirection_=nnDirection}={}) {

        betweenNodesInLayer = betweenNodesInLayer_;
        betweenLayers = betweenLayers_;
        nnDirection = nnDirection_;

        let layer_widths = architecture
            .map((layer_width, i) => layer_width <= nodesInLayerBeforeSplitting 
                ? layer_width 
                : 2*nAboveBelow + ellipsisGap)
            .map((layer_width, i) => layer_width * nodeDiameter + (layer_width - 1) * betweenNodesInLayer[i])

        largest_layer_width = Math.max(...layer_widths);

        layer_offsets = layer_widths.map(layer_width => (largest_layer_width - layer_width) / 2);

        let indices_from_id = (id) => id.split('_').map(x => parseInt(x));
        let relative_node_indices_from_node = (node) => [node.layer, node.relative_node_index];

        let x = (layer, node_index) => {
            return layer * (betweenLayers + nodeDiameter) + w/2 - (betweenLayers * layer_offsets.length/3);
        };
        let y = (layer, node_index) => {
            return layer_offsets[layer] + node_index * (nodeDiameter + betweenNodesInLayer[layer]) + h/2 - largest_layer_width/2
        };

        let xt = (layer, node_index) => {
            return layer_offsets[layer] + node_index * (nodeDiameter + betweenNodesInLayer[layer]) + w/2  - largest_layer_width/2
        };
        let yt = (layer, node_index) => {
            return layer * (betweenLayers + nodeDiameter) + h/2 - (betweenLayers * layer_offsets.length/3)
        };

        if (nnDirection == 'up') { x = xt; y = yt; }

        node.attr('cx', function(d) { return x(d.layer, d.relative_node_index); })
            .attr('cy', function(d) { return y(d.layer, d.relative_node_index); });

        link.attr("d", (d) => "M" + x(...relative_node_indices_from_node(d.start)) + "," +
                                    y(...relative_node_indices_from_node(d.start)) + ", " +
                                    x(...relative_node_indices_from_node(d.end)) + "," +
                                    y(...relative_node_indices_from_node(d.end)));

        text.attr("x", function(d) { return (nnDirection === 'right' ? x(d.layer, d.node_index) - textWidth/2 : w/2 + largest_layer_width/2 + 20 ); })
            .attr("y", function(d) { return (nnDirection === 'right' ? h/2 + largest_layer_width/2 + 20       : y(d.layer, d.node_index) ); });

        elip.attr('cx', function(d) { return x(d.layer, d.relative_node_index); })
            .attr('cy', d => h/2 + (2*nodeDiameter/3)*(d.part - 2));
    }

    function style({
        edgeWidthProportional_=edgeWidthProportional,
        edgeWidth_=edgeWidth,
        edgeOpacityProportional_=edgeOpacityProportional,
        edgeOpacity_=edgeOpacity,
        negativeEdgeColor_=negativeEdgeColor,
        positiveEdgeColor_=positiveEdgeColor,
        edgeColorProportional_=edgeColorProportional,
        defaultEdgeColor_=defaultEdgeColor,
        nodeDiameter_=nodeDiameter,
        nodeColor_=nodeColor,
        nodeBorderColor_=nodeBorderColor,
        showArrowheads_=showArrowheads,
        arrowheadStyle_=arrowheadStyle}={}
    ) {
        //svg.transition().duration(0).style("opacity", 0);
        // Edge Width
        edgeWidthProportional   = edgeWidthProportional_;
        edgeWidth               = edgeWidth_;
        weightedEdgeWidth       = d3.scaleLinear().domain([0, 1]).range([0, edgeWidth]);
        // Edge Opacity
        edgeOpacityProportional = edgeOpacityProportional_;
        edgeOpacity             = edgeOpacity_;
        // Edge Color
        defaultEdgeColor        = defaultEdgeColor_;
        edgeColorProportional   = edgeColorProportional_;
        negativeEdgeColor       = negativeEdgeColor_;
        positiveEdgeColor       = positiveEdgeColor_;
        weightedEdgeColor       = d3.scaleLinear().domain([-1, 0, 1]).range([negativeEdgeColor, "white", positiveEdgeColor]);
        // Node Styles
        nodeDiameter            = nodeDiameter_;
        nodeColor               = nodeColor_;
        nodeBorderColor         = nodeBorderColor_;
        // Arrowheads
        showArrowheads          = showArrowheads_;
        arrowheadStyle          = arrowheadStyle_;

        link.style("stroke-width", function(d) {
            if (edgeWidthProportional) { return weightedEdgeWidth(Math.abs(d.weight)); } else { return edgeWidth; }
        });

        link.style("stroke-opacity", function(d) {
            if (edgeOpacityProportional) { return weightedEdgeOpacity(Math.abs(d.weight)); } else { return edgeOpacity; }
        });

        link.style("stroke", function(d) {
            if (edgeColorProportional) { return weightedEdgeColor(d.weight); } else { return defaultEdgeColor; }
        });

        link.attr('marker-end', showArrowheads ? "url(#arrow)" : '');
        marker.attr('refX', nodeDiameter*1.4 + 12);
        arrowhead.style("fill", arrowheadStyle === 'empty' ? "none" : defaultEdgeColor);

        node.attr("r", nodeDiameter/2);
        node.style("fill", function(d) {
            if (nodeColorProportional) { return nodeColor(d.node_value) } else { return defaultNodeColor; }
        });
        node.style("stroke", nodeBorderColor);

        elip.attr("r", nodeDiameter/6);
        elip.style("fill", function(d) {
            return elipsisColor;
        });
        //svg.transition().duration(200).style("opacity", 1);

    }

    /////////////////////////////////////////////////////////////////////////////
                          ///////    Focus    ///////
    /////////////////////////////////////////////////////////////////////////////

    function set_focus(d) {
        d3.event.stopPropagation();
        node.style("opacity", function(o) { return (d == o || o.layer == d.layer - 1) ? 1 : 0.1; });
        link.style("opacity", function(o) { return (o.target == d.id) ? 1 : 0.02; });
    }

    function remove_focus() {
        d3.event.stopPropagation();
        node.style("opacity", 1);
        link.style("opacity", function () { return edgeOpacity; })
    }

    /////////////////////////////////////////////////////////////////////////////
                          ///////    Zoom & Resize   ///////
    /////////////////////////////////////////////////////////////////////////////

    //svg.call(d3.zoom()
               //.scaleExtent([1 / 2, 8])
               //.on("zoom", zoomed));

    function zoomed() { g.attr("transform", d3.event.transform); }

    function resize() {
        const w = d3.select(".network").style("width");
        const h = d3.select(".network").style("height");
        svg.attr("width", w).attr("height", h);
    }

    //d3.select(window).on("resize", resize)

    resize();

    /////////////////////////////////////////////////////////////////////////////
                          ///////    Return    ///////
    /////////////////////////////////////////////////////////////////////////////

    return {
        'redraw'           : redraw,
        'redistribute'     : redistribute,
        'style'            : style,

        'graph'            : graph,
        'link'             : link
    }

}

export { FCNN };
